<template>
  <ElvMessageBox
    ref="upgradeProjectMessageBoxRef"
    class="elv-upgrade-project-upgrade-plan-dialog"
    :confirmButtonText="t('button.upgrade')"
    :cancelButtonText="t('button.cancel')"
    :title="t('title.upgradeToStandardPlan')"
    :loading="confirmLoading"
    :isDisabledConfirmStatus="!agreeTerms"
    @onCancelEvent="changeUpgradeProjectDialogStatus"
    @onConfirmEvent="onClickConfirm"
  >
    <template #content>
      <div class="elv-upgrade-project-form-content-box">
        <p class="elv-upgrade-project-form-content-title">
          {{ t('message.upgradeStandardPlan') }}
        </p>
        <div class="elv-upgrade-project-form-term-service">
          <div class="elv-upgrade-project-form-term-service-agree">
            <el-checkbox v-model="agreeTerms" size="small" />
            <i18n-t
              keypath="message.agreeServicesAgreement"
              tag="p"
              class="elv-upgrade-project-form-term-service-agree-tip"
              scope="global"
            >
              <template #agreement>
                <span @click="onOpenServicesAgreement">{{ t('common.mainServicesAgreement') }}</span>
              </template>
            </i18n-t>
          </div>
          <i18n-t
            keypath="message.createProjectTermsOfService"
            tag="p"
            class="elv-upgrade-project-form-term-service-terms"
            scope="global"
          >
            <template #policy>
              <span @click="onOpenPrivacyPolicy">{{ t('common.serviceAgreement') }}</span>
            </template>
          </i18n-t>
        </div>
      </div>
    </template>
  </ElvMessageBox>
</template>

<script lang="ts" setup>
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import { ProjectPermissionLevel } from '#/ProjectTypes'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const { t } = useI18n()
const router = useRouter()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()
const menuGlobalStore = useMenuGlobalStore()

const agreeTerms = ref(false)
const confirmLoading = ref(false)
const upgradeProjectMessageBoxRef = useTemplateRef('upgradeProjectMessageBoxRef')

const changeUpgradeProjectDialogStatus = () => {
  upgradeProjectMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 更新项目信息
 */
const refreshProjectInfo = async () => {
  try {
    // 刷新项目列表，刷新当前项目数据ProjectDetail
    await globalStore.fetchProjectList()
    await projectStore.fetchProjectDetail(projectStore.projectDetail?.projectId || '')
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

const onClickConfirm = async () => {
  if (!agreeTerms.value) return
  confirmLoading.value = true
  try {
    if (projectStore.projectDetail?.plan === ProjectPermissionLevel.FREE) {
      const currentProjectId = projectStore.projectDetail?.projectId || ''
      const currentRouterPath = router.resolve({
        query: {
          projectId: currentProjectId
        }
      }).href
      const url = `${import.meta.env.VITE_APP_BASE_URL}${currentRouterPath}`
      const data = await ProjectApi.subscribeProject(currentProjectId, {
        successUrl: url,
        cancelUrl: url
      })
      window.open(data.data, '_self')
      changeUpgradeProjectDialogStatus()
    } else {
      await ProjectApi.upgradeProject(projectStore.projectDetail?.projectId || '')
      await refreshProjectInfo()
      menuGlobalStore.menuListInit()
      changeUpgradeProjectDialogStatus()
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    confirmLoading.value = false
  }
}

/**
 * 打开服务协议
 */
const onOpenServicesAgreement = () => {
  window.open('https://www.elven.com/service-agreement ', '_blank')
}

/**
 * 打开隐私政策
 */
const onOpenPrivacyPolicy = () => {
  window.open('https://www.elven.com/privacy', '_blank')
}

defineExpose({
  changeUpgradeProjectDialogStatus
})
</script>
<style lang="scss">
.elv-upgrade-project-upgrade-plan-dialog {
  width: 620px;

  .el-dialog__body {
    padding: 23px 55px;

    .elv-upgrade-project-form-content-box {
      font-family: 'Plus Jakarta Sans';

      .elv-upgrade-project-form-content-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1e2024;
        white-space: pre;
      }

      .elv-upgrade-project-form-term-service {
        background-color: #f7f9ff;
        border: 1px solid #dde1e6;
        border-radius: 4px;
        padding: 12px;
        color: #4e515a;
        font-size: 13px;
        margin-top: 10px;
        box-sizing: border-box;

        &-agree {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 16px;

          .el-checkbox--small {
            height: 18px;
          }
        }

        &-agree-tip {
          span {
            color: #1753eb;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        &-terms {
          line-height: 17px;
          padding-left: 16px;
          text-align: left;

          span {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
